.error-boundary {
  background-color: #000;
  background-size: cover;
  background-position: center;
  color: #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.error-boundary-box {
  margin: 1em;
  border: 1px solid #fff;
  padding: 1em;
  border-radius: 5px;
}

.error-boundary-header {
  font-weight: bold;
  font-family: "Arial", sans-serif;
  font-size: 1.2em;
}

.error-boundary-stacktrace {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
